<div class="login_wrapper">
<section class="home-search-admin-section">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12">
                <div class="dashboard-search admin-panel-search">
                    <div class="form-group">
                        <i class="fas fa-search"></i>
                        <input class="form-control form-control-lg form-control-borderless" type="search" (keyup)="searchKeyUp($event)" (keydown)="searchPressed($event)" placeholder="Search by Footer link" id="searchKeyword">
                        <button class="btn btn-lg btn-danger" *ngIf="searchText.length>0" (click)="clearSearch()" type="button"></button>
                        <button class="btn btn-lg btn-success" (click)="onClickSearchBar()"></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="add-footer-text">
                    <a href="javascript:;" (click)="openAddEditFooterLinkModal(addEditFooterLinkModalContent, 0)" class="btn-primary-small">+ Create footer link</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="recently-added-heading-main vendro-article-text-new">
                <h4>Footer Links</h4>
            </div>
        </div>
        <div class="col-xl-3"></div>

        <div class="col-xl-3 col-lg-6 col-md-12">
            <div class="sorting-filter-main all-client-list-box">
                <div class="form-group">

                    <div class="select-dropdown">
                        <select class="form-select" aria-label="Default select example" (change)="onChangeSortOrder($event)">
                            <option value="1">Ascending</option>
                            <option value="2">Descending </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-xl-12">
            <div class="recently-added-table home-owner-table table-responsive">
                <table class="table" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Link Titles</th>
                            <th>Links</th>
                            <th>View Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="footerLinks.length>0; else noFooterLinks">
                        <tr *ngFor="let footerLink of footerLinks">
                            <td><div>{{footerLink.linkTitle}}</div></td>
                            <td>
                                <div>{{footerLink.linkUrl}}</div>
                            </td>
                            <td>
                                <ul class="edit-footer-ul">
                                    <li>
                                        <a href="javascript:;" (click)="openDetailArticleModal(detailArticleStep1ModalContent, footerLink.footerLinkId, footerLink.linkUrl, footerLink.linkTitle, footerLink.linkOrder)" class="btn-secondary-small" data-bs-toggle="modal" data-bs-target="#exampleModalMessage">View Details</a>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul class="message-action">
                                    <li><a href="javascript:;" (click)="openAddEditFooterLinkModal(addEditFooterLinkModalContent, footerLink.footerLinkId)"><i
                                                class="fas fa-edit"></i></a></li>
                                    <li><a href="javascript:;" (click)="openRemoveFooterLink(removeFooterLinkAlertModel, footerLink.footerLinkId)"><i
                                                class="fas fa-trash-alt"></i></a></li>
                                </ul>
                            </td>
                            <!-- <td>
                                <ul class="edit-footer-ul">

                                    <li><a href="javascript:;" (click)="openAddEditFooterLinkModal(addEditFooterLinkModalContent, footerLink.footerLinkId)" class="btn-secondary-small"><i class="fas fa-edit"></i></a></li>
                                    <li><a href="javascript:;" (click)="openRemoveFooterLink(removeFooterLinkAlertModel, footerLink.footerLinkId)" class="remove-footer-link"><i class="fas fa-trash-alt"></i></a></li>
                                </ul>
                            </td> -->
                        </tr>
                    </tbody>
                    
                </table>

                <ng-template #noFooterLinks>
                            <div style="text-align: left; width: 100%;
                            padding: 20px 0;    margin: 0 auto;
                            display: block;">No Footer Links yet..!</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #removeFooterLinkAlertModel let-modal>
    <div class="skip-recommendations">
        <div class="modal-header">
            <button type="button" (click)="onRemoveFooterLinkModalCancelClick()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <img src="assets/img/notification-icon.svg" class="notification-icon" alt="modal-home-icon">
            <p>Are you sure you want to Remove this footer link?</p>
            <p class="notification-text-account">This cannot be undone</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onRemoveFooterLinkModalCancelClick()" data-bs-dismiss="modal">No</button>
            <button type="button" (click)="onRemoveFooterLinkModalYesClick()" class="btn btn-primary">Yes</button>
        </div>
    </div>
</ng-template>


<!-- Modal Add/edit footer link-->
<div class="add-footer-link-modal">
    <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <!-- <div class="modal-content"> -->
            <ng-template #addEditFooterLinkModalContent let-modal>
                <form [formGroup]="inputFormFooterLink" (ngSubmit)="onSubmitFooterLink()">
                    <div class="modal-header">
                        <button type="button" (click)="onAddEditFooterLinkModalCancelClick()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="add-footer-modal-heading">
                            <h4 *ngIf="formControls.footerLinkId.value==undefiend || formControls.footerLinkId.value==0">Add Helpful Link</h4>
                            <h4 *ngIf="formControls.footerLinkId.value>0">Edit Helpful Link</h4>
                           
                        </div>

                        <div class="add-footer-modal-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="linkUrl">URL <span>*</span></label>
                                        <input type="text" class="form-control facebook-input" placeholder="Add URL" formControlName="linkUrl" (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputFormFooterLink, 'linkUrl')">
                                        <div *ngIf="(formControls.linkUrl.invalid) && (submitted || formControls.linkUrl.dirty || formControls.linkUrl.touched)" class="text-danger">
                                            <div *ngIf="formControls.linkUrl.errors?.required">
                                                Url is required.
                                            </div>
                                            <div *ngIf="formControls.linkUrl.errors?.pattern">
                                                Please provide valid url.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  col-md-6   -->

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="email">Custom Title <span>*</span></label>
                                        <input type="text" class="form-control" placeholder="Add Custom Title" formControlName="linkTitle" (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputFormFooterLink, 'linkTitle')">
                                        <div *ngIf="(formControls.linkTitle.invalid) && (submitted || formControls.linkTitle.dirty || formControls.linkTitle.touched)" class="text-danger">
                                            <div *ngIf="formControls.linkTitle.errors?.required">
                                                Custom title is required.
                                            </div>
                                            <div *ngIf="formControls.linkTitle?.errors?.pattern">
                                                Invalid Custom title.
                                            </div>
                                            <div *ngIf="formControls.linkTitle?.errors?.maxlength">
                                                Custom title can be max 20 characters long.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  col-md-6   -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="linkOrder">Link Order <span>*</span></label>
                                        <input type="number" class="form-control facebook-input" placeholder="The order to show this link, for example 3" formControlName="linkOrder" (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputFormFooterLink, 'linkOrder')">
                                        <div *ngIf="(formControls.linkOrder.invalid) && (submitted || formControls.linkOrder.dirty || formControls.linkOrder.touched)" class="text-danger">
                                            <div *ngIf="formControls.linkOrder.errors?.required">
                                                Link order is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="onAddEditFooterLinkModalCancelClick()" data-bs-dismiss="modal">No</button>
                        <button type="submit" *ngIf="selectedFooterLinkId==0" class="btn btn-primary">Add Link</button>
                        <button type="submit" *ngIf="selectedFooterLinkId>0" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </ng-template>
            <!-- </div> -->
        </div>
    </div>
</div>

<!-- Detail Vendor Article Modal -->
<div class="add-footer-link-modal">
    <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <!-- <div class="modal-content"> -->
            <ng-template #detailArticleStep1ModalContent let-modal>
                    <div class="modal-header">
                        <button type="button" (click)="onAddEditFooterLinkModalCancelClick()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="add-footer-modal-heading">
                            <h4>Helpful Link Details</h4>
                           
                        </div>

                        <div class="add-footer-modal-form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="linkUrl">URL</label>
                                        <h4>{{selectedFooterLinkUrl}}</h4>
                                    </div>
                                </div>
                                <!--  col-md-6   -->

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="email">Custom Title </label>
                                      <h4>{{selectedFooterLinkTitle}}</h4>
                                    </div>
                                </div>
                                <!--  col-md-6   -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="linkOrder">Link Order </label>
                                       <h4>{{selectedFooterLinkOrder}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    <button type="button" (click)="openAddEditFooterLinkModal(addEditFooterLinkModalContent, selectedFooterLinkId)" class="btn btn-primary">edit Footer Link</button>
                    </div>
            </ng-template>
            <!-- </div> -->
        </div>
    </div>
</div>
