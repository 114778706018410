import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router  } from '@angular/router';
import { Observable } from 'rxjs';
import { AppHttpRequestHandlerService } from './shared/app-http-request-handler.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DynamicPathGuard implements CanActivate {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  dynamicPath: string;
  value1: string;
  isBool: boolean;
  constructor(
    private router: Router,
    private appHttpRequestHandlerService: AppHttpRequestHandlerService // nject your service
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const dynamicPath = route.paramMap.get('dynamicPath'); 
      this.value1 = window.location.origin.concat("/login/"+dynamicPath);
      return this.appHttpRequestHandlerService.httpGet({ id: this.value1 }, "CommonApi", "GetLoginUrl").pipe(
        takeUntil(this.ngUnsubscribe),
        map((data: any) => {
          console.log('dataforimg1', data.enterprise);
          if (data.enterprise === "" || data.enterprise === null) {
            this.isBool = false;
            return false; // Return false to prevent navigation
          } else {
            this.isBool = true;
            return true; // Return true to allow navigation
          }
        })
      );
  }
  
}
